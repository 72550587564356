import { makeAPIRequest } from './studentRequests'
import { pullUserFromServer } from '../logic/pullUserFromServer'

export const getAllClassrooms = async (teacherId) => {
  let response
  try {
    response = await makeAPIRequest(`/classrooms?teacherUserId=${teacherId}`, 'GET')
    if (response.status !== 200) {
      throw new Error(response.message)
    }
    return response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const getLessonSummaries = async (courseId, teacher = false) => {
  let response
  try {
    response = await makeAPIRequest(`/courses/${courseId}/lessonSummaries?latestCourse=${teacher}`, 'GET')
    if (response.status !== 200) {
      throw new Error(response.message)
    }
    return response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const getContentfulCourseId = async () => {
  let response
  try {
    response = await makeAPIRequest(`/courses/contentfulId`, 'GET')
    if (response.status !== 200) {
      throw new Error(response.message)
    }
    return (await response.json()).contentfulId
  } catch (e) {
    throw new Error(e)
  }
}

export const getClassroomSchedule = async (startDate, schoolId, classroomId, blackoutDays) => {
  let response
  const data = {
    startDate,
    schoolId,
    blackoutDays,
  }
  if (classroomId) {
    data.classroomId = classroomId
  }
  try {
    response = await makeAPIRequest('/classrooms/schedule', 'POST', data)
    return (await response.json())
  } catch (e) {
    throw new Error(e)
  }
}

export const postNewClassroom = async (className, schoolId, startDate, teacherUserId, blackoutDays, defaultSchedule = true) => {
  const data = {
    name: className,
    schoolId: +schoolId,
    startDate,
    teacherUserId: +teacherUserId,
    term: '',
    blackoutDays,
    defaultSchedule,
  }
  try {
    const response = await makeAPIRequest('/classrooms', 'POST', data)
    return (await response.json())
  } catch (e) {
    throw new Error(e)
  }
}

export const updateClassroom = async (classroomId, className, startDate, blackoutDays, defaultSchedule) => {
  const data = {
    name: className,
    startDate,
    blackoutDays,
    defaultSchedule,
  }
  let response
  try {
    response = await makeAPIRequest(`/classrooms/${classroomId}`, 'PUT', data)
    return response.json()
  } catch (e) {
    throw new Error(e)
  }
}

// export const deleteClassroom = async (classroomId) => {
//   let response
//   try {
//     response = await makeAPIRequest(`/classrooms/${classroomId}`, 'DELETE')
//     if (response.status === 200) {
//       return [true, true]
//     } else {
//       return [false, (await response.json())]
//     }
//   } catch (e) {
//     throw new Error(e)
//   }
// }

export const getDailyBeatsSummaries = async (courseId, preferenceId = 'bxFwUN03e0KImy8OgsC06') => {
  const data = {
    preferenceId,
  }
  try {
    const response = await makeAPIRequest(`/courses/${courseId}/dailyBeatsSummaries`, 'POST', data)
    return (await response.json())
  } catch (e) {
    throw new Error(e)
  }
}

export const validateStudent = async (validationToken) => {
  const data = {
    token: validationToken,
  }

  try {
    const response = await makeAPIRequest('/users/validate', 'POST', data)
    if (response.status === 200) {
      return [true, true]
    } else {
      return [false, (await response.json()).message]
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const getStudentsForSchool = async (schoolId, classroomId=undefined) => {
  try {
    const response = await makeAPIRequest(`/users?schoolId=${schoolId}${classroomId ? `&classroomId=${classroomId}` : ''}`, 'GET')
    return response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const generatePasswordResetLink = async (classroomId, studentId) => {
  try {
    const response = await makeAPIRequest(`/classrooms/${classroomId}/user/${studentId}/passwordReset`, 'PUT')
    return (await response.json()).url
  } catch (e) {
    throw new Error(e)
  }
}

export const deleteStudent = async (studentId) => {
  try {
    const response = await makeAPIRequest(`/users/${studentId}`, 'DELETE')
    if (response.status === 200) {
      return [true, true]
    } else {
      return [false, (await response.json()).message]
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const resetStudentMode = async () => {
  try {
    const response = await makeAPIRequest('/users/teacher/resetPreview', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error resetting your course. Please try again.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

// export const hideExampleClass = async (type) => {
//   try {
//     const data = {
//       type,
//     }
//     const response = await makeAPIRequest('/users/hideExampleClass', 'POST', data)
//     if (response.status !== 200) {
//       throw new Error('There was an error deleting your example class. Please try again.')
//     }
//   } catch (e) {
//     throw new Error(e)
//   }
// }

export const completeTeacherOrientation = async () => {
  try {
    const response = await makeAPIRequest('/users/completeTeacherOrientation', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const dismissStudentViewCallout = async () => {
  try {
    const response = await makeAPIRequest('/users/dismissStudentViewCallout', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const setSeenWelcomeToStudentViewToTrue = async () => {
  try {
    const response = await makeAPIRequest('/users/setSeenWelcomeToStudentViewToTrue', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const setSeenAgeAndConsentDisclaimerStatusToHasSeen = async () => {
  try {
    const response = await makeAPIRequest('/users/setSeenAgeAndConsentDisclaimerStatusToHasSeen', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const completeContentForStudent = async ({
  studentId,
  classroomId,
  contentIndex,
  contentType
}) => {
  try {
    const body = {
      contentIndex,
      contentType
    }
    const response = await makeAPIRequest(`/classrooms/${classroomId}/students/${studentId}/completeContent`, 'POST', body)
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }

}

export const moveStudent = async ({ studentId, newClassroomId, currentClassroomId }) => {
  const data = {
    currentClassroomId,
    newClassroomId,
    studentId,
  }
  try {
    const response = await makeAPIRequest('/classrooms/moveStudent', 'PUT', data)
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const getMostRecentFocusSeshDataForEachStudentInClassroom = async (classroomId) => {
  let response
  try {
    response = await makeAPIRequest(`/classrooms/${classroomId}/mostRecentFocusSeshDataForEachStudent`, 'GET')
    if (response.status !== 200) {
      throw new Error(response.message)
    }
    return response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const dismissArchivingClassesCallout = async () => {
  try {
    const response = await makeAPIRequest('/users/dismissArchivingClassesCallout', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const setSeenTenDayCourseTabInfoToTrue = async () => {
  try {
    const response = await makeAPIRequest('/users/setSeenTenDayCourseTabInfoToTrue', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const setSeenImportantInfoAboutNewClassToTrue = async () => {
  try {
    const response = await makeAPIRequest('/users/setSeenImportantInfoAboutNewClassToTrue', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const archiveClassroom = async (classroomId) => {
  try {
    const response = await makeAPIRequest(`/classrooms/${classroomId}/archive`, 'PUT')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const restoreClassroom = async (classroomId) => {
    try {
        const response = await makeAPIRequest(`/classrooms/${classroomId}/restore`, 'PUT')
      if (response.status !== 200) {
        throw new Error('There was an error.')
      }
    } catch (e) {
        throw new Error(e)
    }
}

export const getInitialLessonsForClassroomMode = async (classroomId) => {
  try {
    const response = await makeAPIRequest(`/userLessons/${classroomId}/lessons/classroomMode`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error()
  }
}

export const getInitialDailyBeatsForClassroomMode = async (classroomId) => {
  try {
    const response = await makeAPIRequest(`/classrooms/${classroomId}/dailyVideosV2/classroomMode`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error()
  }
}

export const initializeAIThread = async (data) => {
  try {
    const response = await makeAPIRequest('/openAI/initThread', 'POST', data)
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
      throw new Error(e)
  }
}

export const streamAIResponse = (threadId) => {
  const DOMAIN = process.env.NEXT_PUBLIC_BACKEND_DOMAIN
  try {
    return new EventSource(`${DOMAIN}/openAI/streamAssistantResponse?threadId=${threadId}`)
  } catch (e) {
    throw new Error(e)
  }
}

export const cancelAIStream = async (threadId, runId) => {
  try {
    const response = await makeAPIRequest(`/openAI/cancelStream?threadId=${threadId}&runId=${runId}`, 'POST')
    if (response.status !== 200) {
      console.error('Failed to cancel stream')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const createAISupportQueryResponseLog = async (data) => {
  try {
    const response = await makeAPIRequest('/users/createAISupportQueryResponseLog', 'POST', data)
    if (response.status !== 200) {
      throw new Error()
    }
  } catch (e) {
      throw new Error()
  }
}

export const postDismissAISupportFeatureTag = async () => {
  try {
    const response = await makeAPIRequest('/users/dismissAISupportFeatureTag', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
    await pullUserFromServer()
  } catch (e) {
    throw new Error(e)
  }
}

export const setSeenPreviousCourseProgressWarning = async (classroomId) => {
  try {
    const response = await makeAPIRequest(`/classrooms/setSeenPreviousCourseProgressWarning/${classroomId}`, 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const getStudentsAndClassroomDataFromClassroomId = async (classroomId) => {
    try {
      const response = await makeAPIRequest(`/classrooms/${classroomId}/getStudentsAndClassroomFromClassroomId`, 'GET')
      if (response.status !== 200) {
        throw new Error()
      }
      return await response.json()
    } catch (e) {
        throw new Error()
    }
}

