import App from 'pages/_app'
import { render } from '@testing-library/react'
import {
  getDailyBeats,
  getDetailedLesson, getFocusSeshLogs, getFocusSeshPresets, getInitialDailyBeats,
  getInitialLessons, getNextDailyBeatItem,
  getUser,
  postUpdateLessonProgress,
} from '../requests/studentRequests'
import {
  getAllClassrooms,
  getContentfulCourseId, getInitialDailyBeatsForClassroomMode, getInitialLessonsForClassroomMode,
  getLessonSummaries,
  getMostRecentFocusSeshDataForEachStudentInClassroom, getStudentsAndClassroomDataFromClassroomId,
  getStudentsForSchool,
} from '../requests/teacherRequests'


export const isTestEnv = () => {
  return process.env.NODE_ENV === 'test'
}

export const renderInApp = (component, pageProps={}) => {
  return render(<App Component={component} pageProps={pageProps}/>)
}

export const mockRouterPath = (pathname, query = {}, backMock = jest.fn(), pushMock = jest.fn(), replaceMock = jest.fn()) => {
  const useRouter = jest.spyOn(require('next/router'), 'useRouter')
  useRouter.mockImplementation(() => ({
    asPath: pathname,
    pathname,
    query,
    back: backMock,
    push: pushMock,
    replace: replaceMock,
  }))
}

export const mockRequests = (
  {
    testLessons = require('tests/testData/lessons/lessonOneStartedNoneComplete.json'),
    testDailyBeats = require('tests/testData/dailyBeats/noCompleteDailyBeats.json'),
    testNextDailyBeat = require('tests/testData/nextDailyBeatItem/nextDailyBeatCard.json'),
    testUser = require('tests/testData/users/testUserResponse.json'),
    testDetailedLesson = require('tests/testData/lessonDetails/lessonDetailsCardTextAndImage.json'),
    testLessonSummaries = require('tests/testData/lessonSummaries/testLessonSummaries.json'),
    testUserLessonsPost = require('tests/testData/userLessonsPost/responseWithoutAnswerItem.json'),
    focusSeshPresets = require('tests/testData/focusSeshPresets/populatedPresets.json'),
    focusSeshLogs = [],
  }) => {
  getInitialLessons.mockImplementation(async () => testLessons)
  getInitialDailyBeats.mockImplementation(async () => testDailyBeats)
  getNextDailyBeatItem.mockImplementation(async () => testNextDailyBeat)
  getUser.mockImplementation(async () => testUser)
  getDetailedLesson.mockImplementation(async () => testDetailedLesson)
  postUpdateLessonProgress.mockImplementation(async () => testUserLessonsPost)
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getContentfulCourseId.mockImplementation(async () => 'courseId')
  getFocusSeshPresets.mockImplementation(async () => focusSeshPresets)
  getFocusSeshLogs.mockImplementation(async () => focusSeshLogs)
}

export const mockTeacherRequests = (
  {
    testClassrooms = require('tests/testData/classrooms/testClassrooms.json'),
    testClassroom = null,
    testLessonSummaries = require('tests/testData/lessonSummaries/testLessonSummaries.json'),
    testStudents = require('tests/testData/students/testStudentsResponse.json'),
    testTeacher = require('tests/testData/users/testUserResponseTeacher-Approved.json'),
    testLessons = require('tests/testData/lessons/lessonOneStartedNoneComplete.json'),
    testInitialLessonsForClassroomMode = require('tests/testData/lesssonsForClassroomMode/initialLessonsForClassroomMode.json'),
    testDailyBeats = require('tests/testData/dailyBeats/noCompleteDailyBeats.json'),
    testInitialDailyBeatsForClassroomMode = require('tests/testData/dailyBeatsForClassroomMode/initialDailyBeatsForClassroomMode.json'),
    testNextDailyBeat = require('tests/testData/nextDailyBeatItem/nextDailyBeatCard.json'),
    testDetailedLesson = require('tests/testData/lessonDetails/lessonDetailsCardTextAndImage.json'),
    testUserLessonsPost = require('tests/testData/userLessonsPost/responseWithoutAnswerItem.json'),
    mostRecentFocusSeshDataForEachStudent = require('tests/testData/mostRecentFocusSeshDataForEachStudent/classroomId-5446-allStudentsInactive.json')
  }) => {
  getAllClassrooms.mockImplementation(async () => testClassrooms)
  getStudentsAndClassroomDataFromClassroomId.mockImplementation(async () => ({ classroom: testClassroom, students: testStudents }))
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getUser.mockImplementation(async () => testTeacher)
  getStudentsForSchool.mockImplementation(async () => testStudents)
  getContentfulCourseId.mockImplementation(async () => 'courseId')

  getInitialLessons.mockImplementation(async () => testLessons)
  getInitialDailyBeats.mockImplementation(async () => testDailyBeats)
  getNextDailyBeatItem.mockImplementation(async () => testNextDailyBeat)
  getDetailedLesson.mockImplementation(async () => testDetailedLesson)
  postUpdateLessonProgress.mockImplementation(async () => testUserLessonsPost)
  getLessonSummaries.mockImplementation(async () => testLessonSummaries)
  getMostRecentFocusSeshDataForEachStudentInClassroom.mockImplementation(async () => mostRecentFocusSeshDataForEachStudent)
  getInitialLessonsForClassroomMode.mockImplementation(async () => testInitialLessonsForClassroomMode)
  getInitialDailyBeatsForClassroomMode.mockImplementation(async () => testInitialDailyBeatsForClassroomMode)
}
