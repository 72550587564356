import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectNumClassrooms = (state) => {
  const numExampleClassrooms = state.classrooms.filter((classroom) => classroom.exampleClass).length
  return state.classrooms.length - numExampleClassrooms
}

export const selectActiveClassrooms = (state) => state.classrooms.filter((classroom) => !classroom.archived && !classroom.exampleClass)
export const selectArchivedClassrooms = (state) => state.classrooms.filter((classroom) => classroom.archived && !classroom.exampleClass)
export const selectAllClassrooms = (state) => state.classrooms
export const selectClassroomById = (id) => (state) => state.classrooms.find((classroom) => classroom.id === id)

const mapClassroomToState = (classroom) => ({
  id: classroom.id,
  archived: classroom.archived,
  name: classroom.name,
  numStudents: classroom.numStudents,
  numUnconfirmedStudents: classroom.numUnconfirmedStudents,
  numNotLoggedInStudents: classroom.numNotLoggedInStudents,
  startDate: classroom.startDate,
  defaultSchedule: classroom.defaultSchedule,
  code: classroom.code,
  numAvailableDailyBeats: classroom.numAvailableDailyBeats,
  numDailyBeats: classroom.numDailyBeats,
  numAvailableLessons: classroom.numAvailableLessons,
  numLessons: classroom.numLessons,
  percentLessonsCompleted: classroom.percentLessonsCompleted,
  percentDailyBeatsCompleted: classroom.percentDailyBeatsCompleted,
  notificationOffDate: classroom.notificationOffDate,
  endDate: classroom.endDate,
  lessonUnlockDates: classroom.lessonUnlockDates,
  dailyBeatUnlockDates: classroom.dailyBeatUnlockDates,
  blackoutDays: classroom.blackoutDays,
  exampleClass: false,
  focusSeshLogs: classroom.focusSeshLogs,
  seenPreviousCourseProgressWarning: classroom.seenPreviousCourseProgressWarning,
})

const getDateXDaysInFutureNoWeekends = (momentDate, x) => {
  let subtract = false
  if (x < 0) {
    subtract = true
    x = -1 * x
  }
  for (let i = 0; i < x; i++) {
    if (!subtract) {
      momentDate.add(1, 'days')
    } else {
      momentDate.subtract(1, 'days')
    }
    while ([0, 6].includes(momentDate.day())) { // Day is a weekend
      if (!subtract) {
        momentDate.add(1, 'days')
      } else {
        momentDate.subtract(1, 'days')
      }
    }
  }
  return momentDate
}

export const SEEN_PREVIOUS_COURSE_PROGRESS_WARNING = {
  NOT_APPLICABLE: 'not_applicable',
  HAS_NOT_SEEN: 'has_not_seen',
  HAS_SEEN: 'has_seen',
}

const exampleClasses = [
  {
    id: 'example-active-class',
    name: 'Example Active Class',
    numStudents: 4,
    numUnconfirmedStudents: 0,
    numNotLoggedInStudents: 0,
    startDate: moment().subtract(1, 'weeks').toISOString(),
    code: 'XXXX',
    numAvailableDailyBeats: 3,
    numDailyBeats: 6,
    numLessons: 4,
    numAvailableLessons: 3,
    percentLessonsCompleted: .5,
    percentDailyBeatsCompleted: .5,
    notificationOffDate: null,
    endDate: null,
    lessonUnlockDates: [
      getDateXDaysInFutureNoWeekends(moment(), -5).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), -4).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), -1).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), 2).format('YYYY-MM-DD'),
    ],
    dailyBeatUnlockDates: [
      getDateXDaysInFutureNoWeekends(moment(), -3).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), -2).format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), 1).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), 3).format('YYYY-MM-DD'),
      getDateXDaysInFutureNoWeekends(moment(), 4).format('YYYY-MM-DD'),
    ],
    exampleClass: true,
    blackoutDays: [],
    focusSeshLogs: [
      // STUDENT A
      {
        id: 1,
        userId: 'example-active-1',
        completed: true,
        numCheckIns: 3,
        secondsDuration: 1200,
        createdAt: moment().subtract(1, 'day').toISOString(),
        updatedAt: moment().subtract(1, 'day').toISOString(),
      },
      {
        id: 3,
        userId: 'example-active-1',
        completed: true,
        numCheckIns: 4,
        secondsDuration: 1200,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      },
      {
        id: 4,
        userId: 'example-active-1',
        completed: true,
        numCheckIns: 4,
        secondsDuration: 1200,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      },
      // STUDENT B
      {
        id: 2,
        userId: 'example-active-2',
        completed: true,
        numCheckIns: 4,
        secondsDuration: 1500,
        createdAt: moment().subtract(2, 'days').toISOString(),
        updatedAt: moment().subtract(2, 'days').toISOString(),
      },
      {
        id: 5,
        userId: 'example-active-2',
        completed: true,
        numCheckIns: 5,
        secondsDuration: 1500,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      },
      // STUDENT C (no sessions)
      // STUDENT D
      {
        id: 6,
        userId: 'example-active-4',
        completed: true,
        numCheckIns: 2,
        secondsDuration: 900,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      },
    ],
    seenPreviousCourseProgressWarning: SEEN_PREVIOUS_COURSE_PROGRESS_WARNING.NOT_APPLICABLE,
  },
]

const classroomsSlice = createSlice({
  name: 'classrooms',
  initialState: [
  ],
  reducers: {
    loadClassrooms: (state, {
      payload: classrooms,
    }) => {
      const mappedClassrooms = classrooms.map((classroom) => mapClassroomToState(classroom))
      return exampleClasses.concat(mappedClassrooms)
    },
    updateSeenPreviousCourseProgressWarning: (state, { payload: { classroomId, warningStatus } }) => {
      const classroom = state.find(classroom => classroom.id === classroomId)
      if (classroom) {
        classroom.seenPreviousCourseProgressWarning = warningStatus
      }
    },
    refreshSingleClassroom: (state, { payload: classroom }) => {
      if (classroom) {
        return state.map(c => c.id === classroom?.id ? mapClassroomToState(classroom) : c)
      }
    }
  },
})

export const { loadClassrooms, updateSeenPreviousCourseProgressWarning, refreshSingleClassroom } = classroomsSlice.actions

export default classroomsSlice.reducer
