export const ImagesToPreload = [
  // Login
  '/static/eye-off.png',
  '/static/eye.png',
  '/static/google-logo.png',
  '/static/apple-icon.png',

  // Teacher Interface
  '/static/close.png',
  '/static/alert-50.png',

  // Student Interface
  '/static/close.png',
  '/static/gray-lock.png',
  '/static/caught-up-check.png',
  '/static/arrow-ios-right.png',
  '/static/lottie/FocusSeshLoading.json',
]
