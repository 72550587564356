import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'

export const selectStudentsByClassroomId = (classroomId) => (state) => state.students.filter((student) => student.classroomId === classroomId)
export const selectStudentById = (studentId) => (state) => state.students.find((student) => student.id === studentId)

export const exampleStudents = [
  {
    id: 'example-active-1',
    firstName: 'Student',
    lastName: 'A',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: [
      { status: 'completed', completedOn: moment().subtract(3, 'days').format('YYYY-MM-DD') },
      { status: 'in progress', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    dailyBeats: [
      { status: 'completed', completedOn: moment().subtract(2, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().subtract(1, 'days').format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    emailAddress: 'studenta@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 30,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-2',
    firstName: 'Student',
    lastName: 'B',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: [
      { status: 'completed', completedOn: moment().subtract(3, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().subtract(2, 'days').format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    dailyBeats: [
      { status: 'completed', completedOn: moment().subtract(2, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().subtract(1, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    emailAddress: 'studentb@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 50,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-3',
    firstName: 'Student',
    lastName: 'C',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: [
      { status: 'completed', completedOn: moment().subtract(3, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().subtract(2, 'days').format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    dailyBeats: [
      { status: 'completed', completedOn: moment().subtract(2, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().subtract(1, 'days').format('YYYY-MM-DD') },
      { status: 'completed', completedOn: moment().format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    emailAddress: 'studentc@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 60,
    totalCoursePoints: 100,
  },
  {
    id: 'example-active-4',
    firstName: 'Student',
    lastName: 'D',
    validated: true,
    hasLoggedInToNativeApp: true,
    classroomId: 'example-active-class',
    lessons: [
      { status: 'completed', completedOn: moment().subtract(3, 'days').format('YYYY-MM-DD') },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    dailyBeats: [
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
      { status: 'not started', completedOn: null },
    ],
    emailAddress: 'studentd@gmail.com',
    validationToken: null,
    exampleStudent: true,
    coursePoints: 10,
    totalCoursePoints: 100,
  },
]

const studentsSlice = createSlice({
  name: 'students',
  initialState: [
  ],
  reducers: {
    loadStudents: (state, {
      payload: students,
    }) => {
      const mappedStudents = students.map((student) => ({
        id: student.id,
        firstName: student.firstName,
        lastName: student.lastName,
        validated: student.validated,
        hasLoggedInToNativeApp: student.hasLoggedInToNativeApp,
        classroomId: student.classroomId,
        lessons: student.lessons,
        dailyBeats: student.dailyBeats,
        emailAddress: student.emailAddress,
        validationToken: student.validationToken,
        exampleStudent: false,
        coursePoints: student.coursePoints,
        totalCoursePoints: student.totalCoursePoints,
      }))
      return exampleStudents.concat(mappedStudents)
    }
  },
})

export const { loadStudents } = studentsSlice.actions

export default studentsSlice.reducer
