import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectFocusSeshLogs = (state) => state.focusSeshLogs
export const selectCompletedFocusSeshLogs = (state) => state.focusSeshLogs.filter((log) => log.completed || log.isSyncedSesh)
export const selectCompletedFocusSeshLogsAfterMomentDateTime = (momentDateTime) => (state) => state.focusSeshLogs.filter((log) => log.completed && moment(log.createdAt).isAfter(momentDateTime))
export const selectHasCompletedFocusSeshWithAutoCheckIns = (state) => state.focusSeshLogs.some((log) => log.completed && log.isAutoCheckIns)

const focusSeshLogsSlice = createSlice({
  name: 'focusSeshLog',
  initialState: [{
    id: null, // number
    completed: null, // bool
    secondsDuration: null, // number
    isAutoCheckIns: null, // bool
    checkInTimingLowerLimit: null, // number
    checkInTimingUpperLimit: null, // number
    numCheckIns: null, // number
    avgCheckInResponseValue: null, // float
    createdAt: null, // timestamp
    isSyncedSesh: null, // bool
  }],
  reducers: {
    loadFocusSeshLogs: (state, {
      payload: focusSeshLogs,
    }) => focusSeshLogs,
    addFocusSeshLog: (state, {
      payload: focusSeshLog,
    }) => {
      // if log already exists in state, update log, otherwise add it
      const existingLogIndex = state.findIndex((log) => log.id === focusSeshLog.id)
      if (existingLogIndex === -1) {
        state.push(focusSeshLog)
      } else {
        state[existingLogIndex] = focusSeshLog
      }
    },
    updateFocusSeshLog: (state, { payload: { id, updates } }) => {
      const index = state.findIndex((log) => log.id === id)
      if (index !== -1) {
        state[index] = { ...state[index], ...updates }
      }
    },
  },
})

export const { loadFocusSeshLogs, addFocusSeshLog, updateFocusSeshLog } = focusSeshLogsSlice.actions

export default focusSeshLogsSlice.reducer
