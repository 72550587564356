import { createSlice } from '@reduxjs/toolkit'

const aiChatThreadSlice = createSlice({
  name: 'aiChatThread',
  initialState: {
    isChatOpen: false,
    threadId: null,
    messages: [],
  },
  reducers: {
    loadAIChatThread: (state, action) => {
      state.threadId = action.payload.threadId
      state.messages = action.payload.messages
    },
    updateThreadId: (state, action) => {
      state.threadId = action.payload
    },
    updateIsChatOpen: (state, action) => {
      state.isChatOpen = action.payload
    }
  },
})

export const { loadAIChatThread, updateIsChatOpen } = aiChatThreadSlice.actions

export const selectAIChatThread = (state) => state.aiChatThread.messages
export const selectThreadId = (state) => state.aiChatThread.threadId
export const selectIsChatOpen = (state) => state.aiChatThread.isChatOpen

export default aiChatThreadSlice.reducer
