import environment from '../constants/environment'
import Router from 'next/router'
import logout from '../logic/logout'
import { pullUserFromServer } from '../logic/pullUserFromServer'

const DOMAIN = process.env.NEXT_PUBLIC_BACKEND_DOMAIN
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT
const USE_TEST_DATA = false
const lessonDetailsTestResponse = require('tests/testData/lessonDetails/lessonDetailsFillInTheBlankQuestion.json')

const DONT_REDIRECT_ON_401_ENDPOINT = ['/login', '/login/google-sso', '/login/sso', '/users/changePassword', '/notificationTokens/logout', '/users/setNotificationPreference', '/users/getNotificationPreferences']

export const createAccount = async (
  {
    code,
    emailAddress,
    firstName,
    lastName,
    password,
    teacher = false,
    schoolName = null,
    jobTitle = null,
    district = null,
    county = null,
    state = null,
    country = null,
    educatorType = null,
    ssoToken = null,
    ssoType = null,
    personal=false,
  },
) => {
  const data = {
    code,
    emailAddress,
    firstName,
    lastName,
    password,
    teacher,
    schoolName,
    jobTitle,
    district,
    county,
    state,
    country,
    educatorType,
    personal,
    ssoToken,
    ssoType,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  let response
  try {
    response = await makeAPIRequest('/users', 'POST', data)
  } catch (e) {
    throw new Error('There was an error creating your account. Please try again.')
  }

  if (response.status === 201) {
    return [true, (await response.json()).jwtToken]
  }

  let responseJson
  try {
    responseJson = await response.json()
  } catch {
    throw new Error(`There was an error creating your account. Please try again.${!personal ? ' If the issue persists, please let your teacher know.' : ''}`)
  }
  throw new Error(responseJson.code)
}

// export const verifyInviteCodeWithServer = async (inviteCode) => {
//   const data = {
//     code: inviteCode,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/classrooms/code', 'POST', data)
//   } catch (e) {
//     throw new Error(e)
//   }
//   if (response.status === 200) {
//     return true
//   }
//   if (response.status === 400) {
//     return false
//   }
//   throw new Error('Failure validating API code')
// }

// /* Returns 200 if valid email address. Returns 400 if email address is already in use. */
// export const validateEmailAddressWithServer = async (emailAddress) => {
//   const data = {
//     emailAddress,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/users/email', 'POST', data)
//   } catch (e) {
//     throw new Error(e)
//   }
//   if (response.status === 200) {
//     return true
//   }
//   if (response.status === 400) {
//     return false
//   }
//   throw new Error('Failure validating Email Address')
// }

// export const postLead = async (
//   {
//     firstName,
//     lastName,
//     schoolEmail,
//     school,
//     jobTitle,
//   },
// ) => {
//   const data = {
//     firstName,
//     lastName,
//     schoolEmail,
//     school,
//     jobTitle,
//   }
//   try {
//     const response = await makeAPIRequest('/lead', 'POST', data)
//     if (response.status === 200) {
//       return true
//     }
//     throw new Error('Error posting lead')
//   } catch (e) {
//     throw new Error('Error posting lead')
//   }
// }

// export const postLogin = async ({ emailAddress, password }) => {
//   const data = {
//     emailAddress,
//     password,
//     longSession: true,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/login', 'POST', data)
//   } catch (e) {
//     throw new LoginError('error', 'There was an error logging in. Please try again.')
//   }
//   if (response.status === 200) {
//     const json = await response.json()
//     return json.token
//   }
//   if (response.status === 401) {
//     const json = await response.json()
//     throw new LoginError(json.code, json.message)
//   } else {
//     throw new LoginError('error', 'There was an error logging in. Please try again.')
//   }
// }

// export const forgotPassword = async (emailAddress) => {
//   const data = {
//     emailAddress,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/users/forgotPassword', 'POST', data)
//     if (response.status !== 200) {
//       throw new Error()
//     }
//     return true
//   } catch (e) {
//     throw new Error('Error resetting password')
//   }
// }

export const getUser = async () => {
  let response
  try {
    response = await makeAPIRequest('/users/self', 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const getInitialLessons = async (classroomId) => {
  let response
  if (ENVIRONMENT === environment.DEV && USE_TEST_DATA) return require('tests/testData/lessons/shortenedCourse/shortenedCourse.json')
  try {
    response = await makeAPIRequest(`/userLessons/${classroomId}/lessons`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error()
  }
}

export const getDetailedLesson = async (classroomId, lessonId, startOver=false) => {
  let response
  if (ENVIRONMENT === environment.DEV && USE_TEST_DATA) return lessonDetailsTestResponse
  try {
    response = await makeAPIRequest(`/userLessons/${classroomId}/lessons/${lessonId}?startOver=${startOver}`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error()
  }
}

export const getFullLessonContent = async (classroomId, lessonId) => {
  let response
  try {
    response = await makeAPIRequest(`/userLessons/${classroomId}/lessons/${lessonId}/fullContent`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error(e)
  }
}

// only used for tests?
export const postDailyBeatComplete = async ({ classroomId, dailyBeatId }) => {
  const data = {
    videoId: dailyBeatId,
  }
  try {
    await makeAPIRequest(`/classrooms/${classroomId}/dailyVideos`, 'POST', data)
  } catch (e) {
    throw new Error()
  }
}

export const deleteUser = async () => {
  try {
    await makeAPIRequest('/users/self', 'DELETE')
  } catch (e) {
    throw new Error()
  }
}
export const postUpdateUserName = async ({ firstName, lastName }) => {
  const data = {
    firstName,
    lastName,
  }

  try {
    await makeAPIRequest('/users/self', 'POST', data)
  } catch (e) {
    throw new Error()
  }
}

export const postChangePassword = async ({ currentPassword, newPassword }) => {
  const data = {
    currentPassword,
    password: newPassword,
  }

  try {
    const response = await makeAPIRequest('/users/changePassword', 'POST', data)
    if (response.status === 200) {
      return true
    } else if (response.status === 401) {
      return false
    } else {
      throw new Error()
    }
  } catch (e) {
    throw new Error()
  }
}

// export const postResetUserPassword = async ({ password, token }) => {
//   const data = {
//     token,
//     password,
//   }
//
//   let response
//   try {
//     response = await makeAPIRequest('/users/resetPassword', 'POST', data)
//   } catch (e) {
//     throw new Error('There was a problem resetting your password. Please request a new password reset link and try again.')
//   }
//
//   if (response.status === 200) {
//     const json = await response.json()
//     return json.token
//   } else {
//     throw new Error('Maybe you already used this link to reset your password? Please request a new password reset link and try again.')
//   }
// }

export const postUpdateDailyBeatPreference = async ({ preferenceKey }) => {
  const data = {
    preference: preferenceKey,
  }

  try {
    await makeAPIRequest('/users/preference', 'POST', data)
  } catch (e) {
    throw new Error()
  }
}

export const getDailyBeatPreference = async () => {
  try {
    const response = await makeAPIRequest('/users/preference', 'GET')
    const json = await response.json()
    return json.key
  } catch (e) {
    throw new Error(e)
  }
}

export const postUpdateLessonProgress = async (
  {
    lessonId,
    sectionId,
    itemId,
    answerId,
    answerItemId,
    classroomId,
    longAnswer,
    classroomMode=false,
    excludedStudentIds=[],
  },
) => {
  let response
  if (ENVIRONMENT === environment.DEV && USE_TEST_DATA) return require('tests/testData/userLessonsPost/responseWithoutAnswerItemAnimatedCard.json')

  const data = {
    lessonId,
    sectionId,
    itemId,
    answerId,
    answerItemId,
    classroomId,
    longAnswer,
  }

  if (classroomMode) {
    data.excludedStudentIds = excludedStudentIds
  }

  try {
    if (!classroomMode) {
      response = await makeAPIRequest('/userLessons', 'POST', data)
    } else {
      response = await makeAPIRequest('/userLessons/classroomMode', 'POST', data)
    }
    if (![200, 204].includes(response.status)) {
      throw new Error(response.status)
    } else if (response.status === 200) {
      return await response.json()
    } else {
      return {
        completed: true,
      }
    }
  } catch (e) {
    throw new Error(e)
  }
}

// export const postNotificationToken = async ({
//                                               token,
//                                             }) => {
//   const data = {
//     token,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/notificationTokens', 'POST', data)
//     if (response.status !== 200) {
//       throw new Error(response.message)
//     }
//     return true
//   } catch (e) {
//     throw new Error(e)
//   }
// }
//
// export const postNotificationTokenLogout = async ({
//                                                     token,
//                                                   }) => {
//   const data = {
//     token,
//   }
//   let response
//   try {
//     response = await makeAPIRequest('/notificationTokens/logout', 'POST', data)
//     if (response.status !== 200) {
//       throw new Error(response.message)
//     }
//     return true
//   } catch (e) {
//     throw new Error(e)
//   }
// }

export const putUserTimezone = async ({ timezone }) => {
  const data = {
    timezone,
  }
  let response
  try {
    response = await makeAPIRequest('/users/timezone', 'PUT', data)
    if (response.status !== 200) {
      throw new Error(response.message)
    }
    return true
  } catch (e) {
    throw new Error(e)
  }
}

export const acceptLatestTerms = async () => {
  try {
    const response = await makeAPIRequest('/users/acceptLatestTerms', 'POST')
    if (response.status !== 200) {
      throw new Error('Could not accept terms')
    }
  } catch (e) {
    throw new Error('Could not accept terms')
  }
}

export const getFullDailyBeatContent = async (classroomId, dailyBeatId, isTeacher) => {
  let response
  try {
    response = await makeAPIRequest(`/classrooms/${classroomId}/dailyBeats/${dailyBeatId}/fullContent?isTeacher=${isTeacher}`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error(e)
  }
}

export const getInitialDailyBeats = async (classroomId) => {
  let response
  if (ENVIRONMENT === environment.DEV && USE_TEST_DATA) return require('tests/testData/dailyBeats/shortenedCourse/shortenedCourse.json')
  try {
    response = await makeAPIRequest(`/classrooms/${classroomId}/dailyVideosV2`, 'GET')
    if (response.status !== 200) {
      throw new Error()
    }
    return await response.json()
  } catch (e) {
    throw new Error()
  }
}

export const updateColorSchemePreferenceWithServer = async (
  preference,
) => {
  try {
    const response = await makeAPIRequest('/users/updateColorSchemePreference', 'POST', {
      preference
    })
    if (response.status !== 200) {
      throw new Error('Error updating color scheme')
    }
  } catch {
    throw new Error('Error updating color scheme')
  }
}

export const getNextDailyBeatItem = async (
  {
    classroomId,
    completedItemId,
    answerId,
    dailyBeatId,
    isTeacher,
    excludedStudentIds=[],
    classroomMode=false
  },
) => {
  const body = {
    completedItemId,
    answerId,
    dailyBeatId,
    isTeacher
  }

  try {
    let response
    if (!classroomMode) {
      response = await makeAPIRequest(`/classrooms/${classroomId}/dailyVideosV2`, 'POST', body)
    } else {
      body.excludedStudentIds = excludedStudentIds
      response = await makeAPIRequest(`/classrooms/${classroomId}/dailyVideosV2/classroomMode`, 'POST', body)
    }
    if (response.status === 201) {
      return {
        completed: true,
      }
    } else if (response.status === 200) {
      return response.json()
    } else {
      throw new Error()
    }
  } catch (e) {
    throw new Error()
  }
}

export const getFocusSeshLogs = async () => {
  let response
  try {
    response = await makeAPIRequest('/focusSeshLogs', 'GET')
    if (response.status !== 200) {
      throw new Error('Error retrieving focus sesh logs')
    }
    return (await response.json())
  } catch (e) {
    throw new Error(e)
  }
}

export const getFocusSeshPresets = async () => {
  let response
  try {
    response = await makeAPIRequest('/focusSeshPresets', 'GET')
    if (response.status !== 200) {
      throw new Error('Error retrieving focus sesh presets')
    }
    const responseJson = await response.json()
    return responseJson
  } catch (e) {
    throw new Error(e)
  }
}

// eslint-disable-next-line
export const makeAPIRequest = async (endpoint, method, data = {}, query = {}) => {
  const jwt = localStorage.getItem('access_token')
  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }

  if (method !== 'GET') {
    options.body = JSON.stringify(data)
  }
  const response = await fetch(`${DOMAIN}${endpoint}`, options)

  if (!DONT_REDIRECT_ON_401_ENDPOINT.includes(endpoint) && [403, 401].includes(response.status)) {
    logout(Router)
    throw new Error('Auth token expired')
  }
  return response
}

export const getCurrentStudentInterfaceVersion = async () => {
  try {
    const response = await makeAPIRequest('/users/currentStudentInterfaceVersion', 'GET')
    if (response.status !== 200) {
      throw new Error('There was an error retrieving the current student interface version')
    }
    const data = await response.json()
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const postLastSeenStudentInterfaceVersion = async (version) => {
  try {
    const data = { version }
    const response = await makeAPIRequest('/users/setLastSeenStudentInterfaceVersion', 'POST', data)
    if (response.status !== 200) {
      throw new Error('There was an error setting the last seen student interface version')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const joinAnotherClassroom = async (code, userHasCourseCompletion) => {
  try {
    const response = await makeAPIRequest(`/classrooms/join/${code}/${userHasCourseCompletion}`, 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error joining the classroom')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const convertPersonalToStudentAccountAndJoinClassroom = async (code, userHasCourseCompletion) => {
  try {
    const response = await makeAPIRequest(`/users/convertToStudentAccount/${code}/${userHasCourseCompletion}`, 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error joining the classroom and converting to a student account')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const postDismissFocusCoachConfidentialityMessage = async () => {
  try {
    const response = await makeAPIRequest('/users/dismissFocusCoachConfidentialityMessage', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error.')
    }
    await pullUserFromServer()
  } catch (e) {
    throw new Error(e)
  }
}

export const updateRecentSoundtracks = async (newSoundtrack) => {
  try {
    const response = await makeAPIRequest('/users/updateRecentSoundtracks', 'POST', { newSoundtrack })
    if (response.status !== 200) {
      throw new Error('There was an error updating recent soundtracks')
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const postDismissPiPFeatureTag = async () => {
  try {
    const response = await makeAPIRequest('/users/dismissPiPFeatureTag', 'POST')
    if (response.status !== 200) {
      throw new Error('There was an error dismissing the PiP feature tag')
    }
  } catch (e) {
    throw new Error(e)
  }
}

